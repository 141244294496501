// src/pages/BlogIndex.tsx

import React from "react";
import { graphql, PageProps } from "gatsby";
import Layout from "../components/Layout";
import Card from "../components/Card";

interface BlogPost {
  node: {
    id: string;
    excerpt: string;
    fields: {
      slug: string;
    };
    frontmatter: {
      title: string;
      thumbnail: string;
      category: string;
    };
  };
}

interface BlogIndexProps extends PageProps {
  data: {
    allMdx: {
      edges: BlogPost[];
    };
  };
}

const BlogIndex: React.FC<BlogIndexProps> = ({ data, location }) => {
  const posts = data.allMdx.edges;

  return (
    <>
      <Layout location={location} title="Your Blog Title">
        <h1 className="text-center text-4xl font-bold mt-4 mb-12"></h1>

        <div className="flex flex-wrap -m-4">
          {posts.map(({ node }) => (
            <Card key={node.id} post={node} />
          ))}
        </div>
      </Layout>
    </>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            category
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  width: 600
                  quality: 100
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  }
`;

